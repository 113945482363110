import Axios, { AxiosError, AxiosInstance } from "axios";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { injectable } from "inversify";
import "reflect-metadata";

import config from "../../../config/default";
import AppError from "../../../utils/appError";

import { IHttpService } from "../models/IHttpService";

@injectable()
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default class AxiosHttpService implements IHttpService {
  private httpInstance: AxiosInstance;

  constructor() {
    this.httpInstance = Axios.create({
      timeout: config.api.timeout,
      baseURL: this.getBaseUrl(),
    });
  }

  getBaseUrl() {
    const params = window.location.href.split("?")[1];
    const { url } = Object.fromEntries(
      new URLSearchParams(params).entries()
    ) as {
      url: string;
    };

    const subdomain = url.split(".")[0];

    if (subdomain.includes("dev") || subdomain.includes("localhost"))
      return config.api.url.homologation;

    return config.api.url.production;
  }

  setAuthorization(token: string): void {
    this.httpInstance.defaults.headers.common.Authorization = token;
  }

  getAuthorization(): string | number | boolean {
    return this.httpInstance.defaults.headers.common.Authorization;
  }

  get<T>(path: string, params?: {}): Promise<T> {
    return this.httpInstance
      .get<T>(path, params)
      .then(({ data }) => data)
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        if (error.response && error.response.status === 403) {
          throw new AppError("erro 403", "error");
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          throw new AppError(error.response.data.message, "error");
        } else {
          throw new AppError("Unable to perform operation", "error");
        }
      });
  }

  post<T = any>(path: string, body: any, params?: {}): Promise<T> {
    return this.httpInstance
      .post<T>(path, body, params)
      .then(({ data }) => data)
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        if (error.response && error.response.status === 403) {
          throw new AppError("erro 403", "error");
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          throw new AppError(error.response.data.message, "error");
        } else {
          throw new AppError("Unable to perform operation", "error");
        }
      });
  }

  patch<T = any>(path: string, body?: any, params?: {}): Promise<T> {
    return this.httpInstance
      .patch<T>(path, body, params)
      .then(({ data }) => data)
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        if (error.response && error.response.status === 403) {
          throw new AppError("erro 403", "error");
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          throw new AppError(error.response.data.message, "error");
        } else {
          throw new AppError("Unable to perform operation", "error");
        }
      });
  }

  put<T = any>(path: string, body?: any, params?: {}): Promise<T> {
    return this.httpInstance
      .put<T>(path, body, params)
      .then(({ data }) => data)
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        if (error.response && error.response.status === 403) {
          throw new AppError("erro 403", "error");
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          throw new AppError(error.response.data.message, "error");
        } else {
          throw new AppError("Unable to perform operation", "error");
        }
      });
  }

  delete<T = any>(path: string, params?: {}): Promise<T> {
    return this.httpInstance
      .delete<T>(path, params)
      .then(({ data }) => data)
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        if (error.response && error.response.status === 403) {
          throw new AppError("erro 403", "error");
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          throw new AppError(error.response.data.message, "error");
        } else {
          throw new AppError("Unable to perform operation", "error");
        }
      });
  }
}
