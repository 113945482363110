import { Form, Formik, FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import * as yup from "yup";

import { ArrowBackIos } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, Typography } from "@mui/material";

import { NoVisibleIcon, VisibleIcon } from "../../components/Icons";
import { StyledButton } from "../../components/StyledButton";
import { StyledCard } from "../../components/StyledCard";
import { StyledGridContainer } from "../../components/StyledLayout";
import { StyledTextField } from "../../components/StyledTextField";

import { QueryTypesNewPassword, routesConfig } from "../../config/routes";
import { useIocContext } from "../../contexts/ioc/IocContext";
import { Types } from "../../ioc/types";
import { IAuthService } from "../../modules/user/models/IAuthService";

import { useContextualNavigate } from "../../hooks/ContextualNavigations";
import { useQuery } from "../../hooks/Query";
import AppError from "../../utils/appError";
import { getSnackbar } from "../../utils/Snackbars";

interface IValue {
  newPass: string;
  confirmPass: string;
}

const ChangePassword: React.FC = () => {
  const query = useQuery<QueryTypesNewPassword>();
  let navigate = useContextualNavigate();

  const iocContext = useIocContext();
  const authService = iocContext.serviceContainer.get<IAuthService>(
    Types.User.IAuthService
  );

  const handleClickGoToBackHome = () => {
    navigate(`${routesConfig.LOGIN}`);
  };

  const { enqueueSnackbar } = useSnackbar();

  const [values, setValues] = useState({
    showPassword: false,
    showConfirmPassword: false,
    hasFocus: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };

  const handleOnChangeHasFocus = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const input = (event.target as HTMLInputElement).value;

    if (input.length > 0) {
      setValues({
        ...values,
        hasFocus: true,
      });
    } else {
      setValues({
        ...values,
        hasFocus: false,
      });
    }
  };

  const [successSendEmail, setSuccessSendEmail] = useState(false);
  useEffect(() => {}, [successSendEmail]);

  const validationSchema = yup.object().shape({
    newPass: yup.string().required("Este campo é obrigatório"),
    confirmPass: yup.string().required("Este campo é obrigatório"),
  });

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleSubmit = async (
    values: IValue,
    actions: FormikHelpers<IValue>
  ) => {
    try {
      if (values.newPass !== values.confirmPass) {
        throw new Error(" As senhas Precisam ser Iguais");
      }

      const emailParameter = query.get("email") as string;
      if (!emailParameter) {
        throw new Error(" O email precisa ser informado no parametro da url");
      }

      const codeParameter = query.get("verification_code") as string;
      if (!codeParameter) {
        throw new Error(
          " O codigo de confirmacao precisa ser informado no parametro da url"
        );
      }

      await authService.changePassword({
        email: emailParameter,
        password: values.confirmPass,
        confirmation_code: codeParameter,
      });

      actions.resetForm();

      setSuccessSendEmail(true);

      enqueueSnackbar(
        getSnackbar({
          message: "Senha atualizada com sucesso",
          variant: "success",
        })
      );
    } catch (error) {
      setSuccessSendEmail(false);
      console.log("Nao foi possivel enviar o Email. Error: ", error);

      if (error instanceof AppError) {
        return enqueueSnackbar(
          getSnackbar({ message: error.message, variant: error.variant })
        );
      } else {
        return enqueueSnackbar(
          getSnackbar({
            message: "Não foi possível enviar o email, tente novamente.",
            variant: "error",
          })
        );
      }
    }
  };

  return (
    <Formik
      initialValues={{ newPass: "", confirmPass: "" }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form>
          <StyledGridContainer>
            <StyledCard>
              <IconButton
                onClick={handleClickGoToBackHome}
                edge="end"
                sx={{ display: "flex" }}
              >
                <ArrowBackIos sx={{ color: "primary.main", opacity: "1" }} />
              </IconButton>

              {successSendEmail ? (
                <Box>
                  <Box>
                    <img
                      src="/images/atem-logotipo.png"
                      alt="logotipo"
                      style={{ marginBottom: "2rem" }}
                    />

                    <Typography
                      color="primary"
                      sx={{
                        fontSize: "2.5rem",
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                      }}
                    >
                      Senha Cadastrada
                    </Typography>

                    <Typography
                      color="subTitles.main"
                      sx={{
                        fontWeight: 500,
                        fontSize: "1.6rem",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          width: "70%",
                          margin: "0.6rem",
                          marginBottom: "4rem",
                        }}
                      >
                        {" "}
                        Sua nova senha foi cadastrada com sucesso!{" "}
                      </p>
                    </Typography>
                  </Box>

                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={handleClickGoToBackHome}
                    loading={props.isSubmitting}
                  >
                    Fazer Login
                  </StyledButton>
                </Box>
              ) : (
                <Box>
                  <Box sx={{ my: 4 }}>
                    <Typography
                      color="primary"
                      sx={{
                        fontSize: "3rem",
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                      }}
                    >
                      Esqueceu a senha?
                    </Typography>
                  </Box>

                  <Box sx={{ my: 6, mx: { xs: 0, md: 4 } }}>
                    <StyledTextField
                      name="newPass"
                      value={props.values.newPass}
                      type={values.showPassword ? "text" : "password"}
                      onChange={(e) => {
                        props.handleChange(e);
                        handleOnChangeHasFocus(e);
                      }}
                      onBlur={props.handleBlur}
                      labelprops={{
                        label: "Nova senha",
                        hasTouched: props.touched.newPass,
                        hasErros: props.errors.newPass,
                      }}
                      sx={{
                        mb: "3.6rem",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              sx={{ m: 0 }}
                            >
                              {values.showPassword ? (
                                <NoVisibleIcon
                                  sx={{
                                    color: "black",
                                    opacity: values.hasFocus ? "1" : "0.2",
                                  }}
                                />
                              ) : (
                                <VisibleIcon
                                  sx={{
                                    color: "primary.main",
                                    opacity: values.hasFocus ? "1" : "0.2",
                                  }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />

                    <StyledTextField
                      id="password"
                      name="confirmPass"
                      value={props.values.confirmPass}
                      type={values.showConfirmPassword ? "text" : "password"}
                      onChange={(e) => {
                        props.handleChange(e);
                        handleOnChangeHasFocus(e);
                      }}
                      onBlur={props.handleBlur}
                      labelprops={{
                        label: "Confirme a nova senha",
                        hasTouched: props.touched.confirmPass,
                        hasErros: props.errors.confirmPass,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                              sx={{ m: 0 }}
                            >
                              {values.showConfirmPassword ? (
                                <NoVisibleIcon
                                  sx={{
                                    color: "black",
                                    opacity: values.hasFocus ? "1" : "0.2",
                                  }}
                                />
                              ) : (
                                <VisibleIcon
                                  sx={{
                                    color: "primary.main",
                                    opacity: values.hasFocus ? "1" : "0.2",
                                  }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  </Box>

                  <StyledButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    loading={props.isSubmitting}
                  >
                    Alterar Senha
                  </StyledButton>
                </Box>
              )}
            </StyledCard>
          </StyledGridContainer>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePassword;
