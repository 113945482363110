// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { inject, injectable } from "inversify";
import jwt_decode from "jwt-decode";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Types } from "../../../ioc/types";
import type { IHttpService } from "../../http/models/IHttpService";
import { ChangePasswordAuthService, ForgetPasswordAuthService, IAuthLoginDTO, IAuthLoginResponse, IAuthService, LoginAuthService, LoginAuthServiceResponse, RegisterUserService, TokenDecoded, UpdatePasswordAuthService  } from "../models/IAuthService";

@injectable()
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class AuthService implements IAuthService {

    @inject(Types.IHttpService)
    private httpInstance!: IHttpService;

    public async authLogin(data: IAuthLoginDTO): Promise<IAuthLoginResponse> {
      return await this.httpInstance.post("/auth/login/v2", data);
    }

    private decodeToken(token: string) : TokenDecoded | null {
      if(token){
        const decodedAccessToken = jwt_decode<TokenDecoded>(token);
        return decodedAccessToken;
      }
      return null;
    }

    public async login(data: LoginAuthService): Promise<LoginAuthServiceResponse> {

      const loginResponse = await this.httpInstance.post<LoginAuthServiceResponse>("/auth/login", data);

      const groupCheck = loginResponse.meta.groups.filter((item: string) => item === data.rules);
      if (groupCheck.length === 0) { throw new Error("Erro: Voce nao possui permissao para acessar esse projeto, por favor entre em contato com o administrador para solicitar a sua inclusao ao projeto");}

      return{
          ...loginResponse,
          decodeAccessToken: this.decodeToken(loginResponse.AccessToken),
          isLoginyQrCode: false,
      };
    }
    
    public async forgetPassword(data: ForgetPasswordAuthService): Promise<void> {
        return this.httpInstance.post(`/auth/forget-password`, {
          email: data.email,
          metadata: data.metadata
        });
    }

    public async changePassword(data: ChangePasswordAuthService): Promise<void> {
        return this.httpInstance.post(`/auth/change-password`, {
          email: data.email,
          password: data.password,
          confirmation_code: data.confirmation_code,
        });
    }
    
    public async updatePassword(data: UpdatePasswordAuthService): Promise<void> {
      return this.httpInstance.post(`/auth/update-password` , {
        email: data.email,
        old_password: data.old_password,
        new_password: data.new_password,
      });
    }

    public async register(data: RegisterUserService): Promise<void> {
        return this.httpInstance.post(`/admin/auth/create_account_request`, {
          name: data.name,
          email: data.email,
          password: data.password,
          role: data.role,
        });
      }
}
