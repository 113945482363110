import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { SnackbarProvider } from "notistack";

import { RoutesComponent } from "./components/Routes";
import { AtemTheme } from "./components/Theme";
import { IocProvider } from "./contexts/ioc/IocContext";

function App() {
  return (
    <IocProvider>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        content={(key, message) => message}
      >
        <ThemeProvider theme={AtemTheme()}>
          <CssBaseline />
          <RoutesComponent />
        </ThemeProvider>
      </SnackbarProvider>
    </IocProvider>
  );
}

export default App;
