import { useEffect } from "react";
import Cookies from "universal-cookie";
import config from "../../config/default";
import { routesConfig } from "../../config/routes";
import { useContextualNavigate } from "../../hooks/ContextualNavigations";

function Logout()
{
  const navigate = useContextualNavigate();

  useEffect(() =>
  {
    const cookies = new Cookies();
    cookies.remove('accessToken', { path: '/', domain: config.domain })
    cookies.remove('refreshToken', { path: '/', domain: config.domain })
    cookies.remove('othersProperties', { path: '/', domain: config.domain });
    window.localStorage.clear();
    navigate(routesConfig.LOGIN);
  }, [navigate])
  return <></>;
}

export default Logout;
