import { AtemApplication } from "../../modules/user/domain/constants";
import { CurrentApplication } from "../../utils";
import ForgotPasswordDefault from "./ForgotPasswordDefault";
import ForgotPasswordNotPortal from "./ForgotPasswordNotPortal";

function ForgotPassword() {
  switch (CurrentApplication()) {
    case AtemApplication.CHECKLIST:
    case AtemApplication.SUPPLY_CHAIN:
    case AtemApplication.SALES_PORTAL:
    case AtemApplication.LOADING:
    case AtemApplication.SALES:
      return <ForgotPasswordNotPortal />;
    default:
      return <ForgotPasswordDefault />;
  }
}

export default ForgotPassword;
