import { AtemApplication } from "../../modules/user/domain/constants";
import { CurrentApplication } from "../../utils";
import SecurityAuthDefault from "./SecurityAuthDefault";
import SecurityAuthNotPortal from "./SecurityAuthNotPortal";

function SecurityAuth() {
  switch (CurrentApplication()) {
    case AtemApplication.CHECKLIST:
    case AtemApplication.SUPPLY_CHAIN:
    case AtemApplication.SALES_PORTAL:
    case AtemApplication.LOADING:
    case AtemApplication.SALES:
      return <SecurityAuthNotPortal />;
    default:
      return <SecurityAuthDefault />;
  }
}

export default SecurityAuth;
