export enum UserStatus {
  FORCE_CHANGE_PASSWORD = "FORCE_CHANGE_PASSWORD",
  CONFIRMED = "CONFIRMED",
}

export enum AtemApplication {
  SUPPLY_CHAIN = "SUPPLY_CHAIN",
  SALES_PORTAL = "SALES_PORTAL",
  CUSTOMER_PORTAL = "CUSTOMER_PORTAL",
  CHECKLIST = "CHECKLIST",
  LOADING = "LOADING",
  SALES = "SALES",
}
