import { AtemApplication } from "../../modules/user/domain/constants";
import { CurrentApplication } from "../../utils";
import LoginDefault from "./LoginDefault";
import LoginNotPortalPage from "./LoginNotPortal";

function Login() {
  console.log(CurrentApplication());

  switch (CurrentApplication()) {
    case AtemApplication.CHECKLIST:
    case AtemApplication.SUPPLY_CHAIN:
    case AtemApplication.SALES_PORTAL:
    case AtemApplication.LOADING:
    case AtemApplication.SALES:
      return <LoginNotPortalPage />;
    default:
      return <LoginDefault />;
  }
}

export default Login;
