import { AtemApplication } from "../../modules/user/domain/constants";
import { CurrentApplication } from "../../utils";
import UpdatePasswordDefault from "./UpdatePasswordDefault";
import UpdatePasswordNotPortal from "./UpdatePasswordNotPortal";

function UpdatePassword() {
  switch (CurrentApplication()) {
    case AtemApplication.CHECKLIST:
    case AtemApplication.SUPPLY_CHAIN:
    case AtemApplication.SALES_PORTAL:
    case AtemApplication.LOADING:
    case AtemApplication.SALES:
      return <UpdatePasswordNotPortal />;
    default:
      return <UpdatePasswordDefault />;
  }
}

export default UpdatePassword;
