import { ArrowBackIos, Check } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";
import * as yup from "yup";
import { useHeight } from "../../../hooks/Height";
import { useIocContext } from "../../../contexts/ioc/IocContext";
import {
  useContextualNavigate,
  useQueryParams,
} from "../../../hooks/ContextualNavigations";
import { IAuthService } from "../../../modules/user/models/IAuthService";
import { Types } from "../../../ioc/types";
import { routesConfig } from "../../../config/routes";
import { getSnackbar } from "../../../utils/Snackbars";
import AppError from "../../../utils/appError";
import { StyledTextField } from "../../../components/StyledTextField";
import { StyledButton } from "../../../components/StyledButton";

export const ForgotPasswordMobileView: React.FC = () => {
  const height = useHeight();
  const iocContext = useIocContext();
  const navigate = useContextualNavigate();
  const authService = iocContext.serviceContainer.get<IAuthService>(
    Types.User.IAuthService
  );
  const { queryDict } = useQueryParams();
  const { enqueueSnackbar } = useSnackbar();

  const [values, setValues] = useState({ hasFocus: false });

  const handleOnChangeHasFocus = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const input = (event.target as HTMLInputElement).value;

    if (input.length > 0) {
      setValues({
        ...values,
        hasFocus: true,
      });
    } else {
      setValues({
        ...values,
        hasFocus: false,
      });
    }
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Precisamos de um e-mail válido")
      .required("Este campo é obrigatório"),
  });

  const handleClickGoToBackHome = () => {
    navigate(`${routesConfig.LOGIN}`);
  };

  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        try {
          await authService.forgetPassword({
            email: values.email,
            metadata: {
              url: new URL(queryDict["url"]).origin,
              rules: queryDict["rules"],
            },
          });

          actions.resetForm();

          navigate(routesConfig.CONFIRM_EMAIL);
        } catch (error) {
          if (error instanceof AppError) {
            return enqueueSnackbar(
              getSnackbar({ message: error.message, variant: error.variant })
            );
          } else {
            return enqueueSnackbar(
              getSnackbar({
                message: "Não foi possível enviar o email, tente novamente.",
                variant: "error",
              })
            );
          }
        }
      }}
    >
      {(props) => (
        <Form>
          <Box
            sx={{
              backgroundColor: "background.default",
              display: "flex",
              flexDirection: "column",
              minHeight: { xs: height },
              backgroundImage: `url(/image-login-background-laptop.png)`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "90%",
                margin: "0 auto",
              }}
            >
              <Box sx={{ width: "20%", mb: 10 }}>
                <img alt="Logo ATEM" src="/images/logo.svg" />
              </Box>
            </Box>

            <Card
              sx={{
                backgroundColor: "background.default",
                boxShadow: "none",
                borderRadius: "1.6rem",
                width: "90%",
                display: "flex",
                margin: "0 auto",
              }}
            >
              <CardContent sx={{ px: 3 }}>
                <Grid
                  container
                  rowSpacing={2}
                  alignItems="center"
                  style={{ height: "100%" }}
                >
                  <Grid item container xs={12} rowSpacing={2}>
                    <Grid item xs={12}>
                      <IconButton
                        onClick={handleClickGoToBackHome}
                        edge="end"
                        sx={{ display: "flex" }}
                      >
                        <ArrowBackIos
                          sx={{ color: "primary.main", opacity: "1" }}
                        />
                      </IconButton>
                    </Grid>
                    <Grid item xs={12} justifyContent={"center"}>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "3.2rem",
                            sm: "3.2rem",
                            md: "4rem",
                          },
                          fontFamily: "Poppins",
                          fontWeight: 700,
                          color: "primary.main",
                          textAlign: "center",
                        }}
                      >
                        Digite seu e-mail
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: "1.6rem", md: "2.4rem" },
                          fontWeight: 400,
                          color: "subTitles.main",
                          textAlign: "center",
                        }}
                      >
                        Enviaremos instruções para redefinição de senha
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledTextField
                        type="email"
                        id="email"
                        name="email"
                        value={props.values.email}
                        onChange={(e) => {
                          props.handleChange(e);
                          handleOnChangeHasFocus(e);
                        }}
                        onBlur={props.handleBlur}
                        labelprops={{
                          label: "Email",
                          hasTouched: props.touched.email,
                          hasErros: props.errors.email,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton edge="end" sx={{ m: 0 }}>
                                {props.touched.email && !props.errors.email ? (
                                  <Check
                                    sx={{ color: "#0BB873", opacity: "1" }}
                                  />
                                ) : (
                                  <Check
                                    sx={{ color: "black", opacity: "0" }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    rowSpacing={1}
                    alignSelf="center"
                    container
                    item
                    xs={12}
                    justifyContent={"center"}
                  >
                    <Grid item xs={7}>
                      <StyledButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                        loading={props.isSubmitting}
                      >
                        Continuar
                      </StyledButton>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
