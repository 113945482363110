const config = {
  api: {
    url: {
      homologation: "https://gateway-dev.grupoatem.com.br",
      production: "https://gateway.grupoatem.com.br",
    },
    urlLocal: "http://localhost:8080",
    timeout: 30000,
  },
  auth: { keys: { token: "@ATEM:grupoAtem:tokenGrupoAtem" } },
  domain:
    window.location.hostname === "localhost"
      ? "localhost"
      : `.${window.location.hostname.replace(/^\w+\./, "")}`,
  CONFIRMED: "CONFIRMED",
};

export default config;
