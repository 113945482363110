import useMedia from "use-media";
import ForgotPasswordDesktopView from "./ForgotPasswordDesktopView";
import { ForgotPasswordMobileView } from "./ForgotPasswordMobileView";

const ForgotPasswordDefault: React.FC = () => {
  const isMobile = useMedia({ maxWidth: 1278 });

  if (isMobile) {
    return <ForgotPasswordMobileView />;
  }

  return <ForgotPasswordDesktopView />;
};

export default ForgotPasswordDefault;
