import { ArrowBackIos } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { StyledButton } from "../../components/StyledButton";
import { routesConfig } from "../../config/routes";
import { useContextualNavigate } from "../../hooks/ContextualNavigations";
import { useHeight } from "../../hooks/Height";

export const ForgotPasswordLastStep: React.FC = () => {
  const height = useHeight();
  const navigate = useContextualNavigate();

  const handleClickGoToBackHome = () => {
    navigate(`${routesConfig.LOGIN}`);
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        display: "flex",
        flexDirection: "column",
        minHeight: { xs: height },
        backgroundImage: `url(/image-login-background-laptop.png)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "90%",
          margin: "0 auto",
        }}
      >
        <Box sx={{ width: "20%", mb: 10 }}>
          <img alt="Logo ATEM" src="/images/logo.svg" />
        </Box>
      </Box>

      <Card
        sx={{
          backgroundColor: "background.default",
          boxShadow: "none",
          borderRadius: "1.6rem",
          width: "90%",
          display: "flex",
          margin: "0 auto",
        }}
      >
        <CardContent sx={{ px: 3 }}>
          <Grid
            container
            rowSpacing={2}
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Grid item container xs={12} rowSpacing={2}>
              <Grid item xs={12}>
                <IconButton
                  onClick={handleClickGoToBackHome}
                  edge="end"
                  sx={{ display: "flex" }}
                >
                  <ArrowBackIos sx={{ color: "primary.main", opacity: "1" }} />
                </IconButton>
              </Grid>
              <Grid item xs={12} justifyContent={"center"}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "3.2rem",
                      sm: "3.2rem",
                      md: "4rem",
                    },
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    color: "primary.main",
                    textAlign: "center",
                  }}
                >
                  Confira seu e-mail{" "}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "1.6rem", md: "2.4rem" },
                    fontWeight: 400,
                    color: "subTitles.main",
                    textAlign: "center",
                  }}
                >
                  Nós enviamos instruções sobre uma nova senha para seu e-mail{" "}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              rowSpacing={1}
              alignSelf="center"
              container
              item
              xs={12}
              justifyContent={"center"}
            >
              <Grid item xs={7}>
                <StyledButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => handleClickGoToBackHome()}
                >
                  Continuar
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
