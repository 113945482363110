import { Card, CardProps, CardContent } from "@mui/material";
import { styled } from "@mui/system";

export interface StyledCardProps extends CardProps<React.ElementType> {

}

const StyledCard = styled(
  ({ children, sx, ...props }: StyledCardProps) =>
    <Card
      sx={{
        ...sx,
        boxShadow: "none",
        width: { xs: "97%", md: "80%" },
        background: "white",
        border: "2px solid white",
        borderRadius: "2rem",
        textAlign: "center",
        padding: "1rem",
      }}

      {...props}
    >
      <CardContent
        sx={{
          my: 1,
          mx: 1,
          marginBottom: 6,
          "&:last-child": {
            paddingBottom: 0
          }
        }}>
        {children}
      </CardContent>

    </Card>
)(({ theme }) => ({}));

export { StyledCard };