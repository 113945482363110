import { Form, Formik } from "formik";
import { motion } from "framer-motion";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Link as LinkRouter } from "react-router-dom";
import Cookies from "universal-cookie";
import * as yup from "yup";
import { Buffer } from "buffer";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import {
  useContextualNavigate,
  useQueryParams,
} from "../../../hooks/ContextualNavigations";
import { useIocContext } from "../../../contexts/ioc/IocContext";
import {
  AuthState,
  IAuthService,
} from "../../../modules/user/models/IAuthService";
import { useHeight } from "../../../hooks/Height";
import { Types } from "../../../ioc/types";
import { routesConfig } from "../../../config/routes";
import { UserStatus } from "../../../modules/user/domain/constants";
import config from "../../../config/default";
import AppError from "../../../utils/appError";
import { getSnackbar } from "../../../utils/Snackbars";
import { StyledTextField } from "../../../components/StyledTextField";
import { StyledButton } from "../../../components/StyledButton";

export const MobilePageView: React.FC = () => {
  let navigate = useContextualNavigate();

  const urlString = window.location.href; // Pega o Url inteira, EX: http://localhost:3001/?url=http://localhost:3000/#/dashboard&rules=supply-chain
  if (urlString.indexOf("?") === -1) {
    throw new Error("Ausencia de Parametros na Url");
  }
  const paramString = urlString.split("?")[1]; // Pega apenas a url Parametro, Ex: url=http://localhost:3000/#/dashboard&rules=supply-chain
  const queryString = new URLSearchParams(paramString); // Transforma em objeto chave e valor

  const iocContext = useIocContext();
  const authService = iocContext.serviceContainer.get<IAuthService>(
    Types.User.IAuthService
  );

  const height = useHeight();
  const theme = useTheme();
  const { query } = useQueryParams();

  const { enqueueSnackbar } = useSnackbar();
  const [values, setValues] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Precisamos de um e-mail válido")
      .required("Este campo é obrigatório"),
    password: yup.string().required("Este campo é obrigatório"),
  });

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const [initialEmail, initialPassword] = (() => {
    const rawToken = queryString.get("token");
    if (rawToken) {
      return Buffer.from(rawToken, "base64").toString("utf-8").split(' ') as [string, string];
    }

    return ["", ""]
  })();


  return (
    <Formik
      initialValues={{ email: initialEmail, password: initialPassword, rules: "" }}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        try {

          if (!queryString.get("url")) {
            throw new Error(
              "Ausencia de Parametros na Url - link de Redirecionamento (url off)"
            );
          }
          const redirectURL = queryString.get("url");

          if (!queryString.get("rules")) {
            throw new Error(
              "Ausencia de Parametros na Url - regras do projeto (rules off) "
            );
          }
          const rules = queryString.get("rules");

          values.rules = rules;

          const loginResponse = await authService.authLogin(values);

          if (loginResponse.state === AuthState.SENT) {
            return navigate(routesConfig.SECURITY_AUTH, {
              state: {
                email: values.email,
                password: values.password,
              },
            });
          }

          if (loginResponse.state === AuthState.FAILED_SENT) {
            throw new Error(
              "Tivemos um problema ao enviar o link de verificação para seu email"
            );
          }

          if (
            loginResponse.state === AuthState.FIRST_ACCESS ||
            loginResponse.meta.custom_attributes.userStatus ===
            UserStatus.FORCE_CHANGE_PASSWORD
          ) {
            const extendedPassword = loginResponse.extendedPassword;
            return navigate(`${routesConfig.UPDATE_PASSWORD_FIRST_ACCESS}`, {
              state: {
                password: extendedPassword || values.password,
                email: values.email,
                migration: Boolean(extendedPassword),
              },
            });
          }

          if (
            loginResponse.meta.custom_attributes.userStatus !==
            UserStatus.CONFIRMED
          ) {
            return navigate(`${routesConfig.NEW_PASSWORD()}`);
          }

          console.log("successfully Logged In.");

          const cookies = new Cookies();
          cookies.set("accessToken", loginResponse.AccessToken, {
            path: "/",
            domain: config.domain,
          });
          cookies.set("refreshToken", loginResponse.RefreshToken, {
            path: "/",
            domain: config.domain,
          });

          document.location.href = redirectURL; // A url precisa receber o protocolo http para poder funcionar -> https://example.com
        } catch (error) {
          console.log("Unable To Login... Reason: ", error);
          if (error as AppError) {
            enqueueSnackbar(
              getSnackbar({
                message: error.message,
                variant: "error",
              })
            );
          }
        }
      }}
    >
      {(props) => (
        <Form>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: { xs: height },
              backgroundImage: `url(/image-login-background-laptop.png)`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "90%",
                margin: "0 auto",
              }}
            >
              <Box sx={{ width: "20%", mb: 10 }}>
                <img alt="Logo ATEM" src="/images/logo.svg" />
              </Box>
            </Box>

            <Card
              sx={{
                borderRadius: "1.6rem",
                width: "90%",
                display: "flex",
                margin: "0 auto",
              }}
              component={motion.div}
              initial={{ y: document.documentElement.clientHeight }}
              animate={{ y: 0 }}
              transition={{
                duration: theme.transitions.duration.complex / 1000,
              }}
            >
              <CardContent>
                <Grid container>
                  <Grid item xs={12} justifyContent={"center"}>
                    <Typography
                      color="subTitles.main"
                      sx={{
                        fontSize: { xs: "1.6rem", md: "4rem" },
                        textAlign: "center",
                      }}
                    >
                      Bem vindo
                    </Typography>
                    <Typography
                      color="primary"
                      sx={{
                        fontSize: { xs: "3.2rem" },
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        textAlign: "center",
                      }}
                    >
                      Fazer login
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} mt={8}>
                    <Grid item xs={12}>
                      <StyledTextField
                        type="email"
                        id="email"
                        name="email"
                        value={props.values.email}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        fullWidth
                        placeholder="Digite o e-mail cadastrado"
                        labelprops={{ label: "E-mail" }}
                      />
                      {props.touched.email && props.errors.email && (
                        <div style={{ color: "#D91F05", fontSize: "1.2rem" }}>
                          {props.errors.email}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} mt={3}>
                      <StyledTextField
                        type={values.showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        value={props.values.password}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        fullWidth
                        placeholder="Digite a sua senha"
                        labelprops={{ label: "Senha" }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {values.showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {props.touched.password && props.errors.password && (
                        <div style={{ color: "#D91F05", fontSize: "1.2rem" }}>
                          {props.errors.password}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: "right" }} mt={2}>
                      <Link
                        color="primary.main"
                        underline="none"
                        component={LinkRouter}
                        to={`${routesConfig.FORGOT_PASSWORD}${query}`}
                      >
                        Esqueceu a senha?
                      </Link>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"center"}
                    rowSpacing={0}
                    spacing={0}
                  >
                    <Grid item xs={10}>
                      <StyledButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                        loading={props.isSubmitting}
                      >
                        Fazer login
                      </StyledButton>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <span
                        style={{
                          color: "#3E3D3D",
                          fontWeight: 400,
                          fontFamily: "Montserrat",
                        }}
                      >
                        Ainda não tem uma conta?
                      </span>{" "}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <Link
                        color="primary.main"
                        underline="none"
                        href="https://atem.com.br/seja-um-revendedor/"
                        target={"_blank"}
                        style={{ fontWeight: "bold" }}
                      >
                        Seja um revendedor!
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default MobilePageView;
