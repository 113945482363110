import { HashRouter, Route, Routes } from "react-router-dom";

import { routesConfig } from "../../config/routes";

import Logout from "../../pages/Logout";

import ChangePassword from "../../pages/ChangePassword";
import ForgotPassword from "../../pages/ForgotPassword";
import UpdatePassword from "../../pages/UpdatePassword";

import { ForgotPasswordLastStep } from "../../pages/ForgotPasswordLastStep";
import SecurityAuth from "../../pages/SecurityAuth";

import Login from "../../pages/Login";

export const RoutesComponent: React.FC = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path={routesConfig.HOME} element={<Login />} />
        <Route path={routesConfig.LOGIN} element={<Login />} />
        <Route path={routesConfig.LOGOUT} element={<Logout />} />
        <Route
          path={routesConfig.FORGOT_PASSWORD}
          element={<ForgotPassword />}
        />
        <Route
          path={routesConfig.CONFIRM_EMAIL}
          element={<ForgotPasswordLastStep />}
        />
        <Route path={routesConfig.SECURITY_AUTH} element={<SecurityAuth />} />
        <Route
          path={routesConfig.NEW_PASSWORD()}
          element={<ChangePassword />}
        />
        <Route
          path={routesConfig.UPDATE_PASSWORD_FIRST_ACCESS}
          element={<UpdatePassword />}
        />
      </Routes>
    </HashRouter>
  );
};
