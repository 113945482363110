import "../style.css";
import { FormikProps } from "formik";
import React, { useState } from "react";
import ReactCodeInput from "react-code-input";
import { Link as LinkRouter } from "react-router-dom";

import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { IAuthLoginDTO } from "../../../modules/user/models/IAuthService";
import {
  useContextualNavigate,
  useQueryParams,
} from "../../../hooks/ContextualNavigations";
import { routesConfig } from "../../../config/routes";
import AnimatedCard from "../../../components/AnimatedCard";
import { StyledCard } from "../../../components/StyledCard";
import { StyledButton } from "../../../components/StyledButton";

interface SecurityAuthCardProps {
  formikProps: FormikProps<IAuthLoginDTO>;
  email: string;
  onResendCode?: () => Promise<void>;
}

const SecurityAuthCard: React.FC<SecurityAuthCardProps> = ({
  formikProps,
  email,
  onResendCode,
}) => {
  const theme = useTheme();
  const { query } = useQueryParams();
  const navigate = useContextualNavigate();
  const [isLoadingResendCode, setIsLoadingResendCode] = useState(false);

  const handleResendCode = async () => {
    setIsLoadingResendCode(true);
    await onResendCode();
    setIsLoadingResendCode(false);
  };

  const initialValue = "000000";
  const [code, setCode] = useState<string>(initialValue);

  const handleCodeChange = (code: string) => {
    setCode(code);
    formikProps.setFieldValue("mfaToken", code);
  };

  function registerInputEvents() {
    const inputs = document.querySelectorAll(".code-input input");

    inputs.forEach((input, index) => {
      input.addEventListener("input", handleInputChange);
    });
  }

  function handleInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    input.style.opacity = "1";
  }

  setTimeout(registerInputEvents, 100);

  const handleClickGoToBackHome = () => {
    navigate(`${routesConfig.LOGIN}`);
  };

  return (
    <AnimatedCard>
      <StyledCard
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "4rem",
          zIndex: 1,
        }}
        cardContentProps={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container justifyContent={"center"} gap={1}>
          <Grid item xs={12} mb={4}>
            <IconButton
              onClick={handleClickGoToBackHome}
              edge="end"
              sx={{ display: "flex" }}
            >
              <ArrowBackIos sx={{ color: "primary.main", opacity: "1" }} />
            </IconButton>
          </Grid>
          <Grid item xs={12} sx={{ border: "0px solid red" }}>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "2.3rem",
                textAlign: "center",
                fontWeight: 800,
                color: theme.palette.primary.main,
              }}
            >
              Autenticação de Segurança
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "1.3rem",
                textAlign: "center",
                fontWeight: 500,
                color: theme.palette.subTitles.main,
              }}
            >
              Enviamos o código de verificação para o seguinte e-mail:
            </Typography>
          </Grid>
          <Grid item xs={10} sx={{ mt: 2 }}>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "1.3rem",
                textAlign: "center",
                fontWeight: 700,
                color: theme.palette.subTitles.main,
              }}
            >
              {email}
            </Typography>
            <Link
              sx={{
                textDecoration: "none",
              }}
              component={LinkRouter}
              to={`${routesConfig.LOGIN}${query}`}
            >
              <Typography
                fontSize="1.4rem"
                fontWeight={600}
                sx={{
                  fontFamily: "Montserrat",
                  color: theme.palette.primary.main,
                }}
              >
                Trocar de e-mail
              </Typography>
            </Link>
          </Grid>
        </Grid>

        {/* CAMPO DE ENTRADA DO CODIGO DE SEGURANCA */}
        <Grid container mt={6}>
          <Grid item xs={12}>
            <ReactCodeInput
              name="mfaToken"
              className="code-input"
              type="number"
              fields={6}
              inputMode={"numeric"}
              value={code}
              onChange={handleCodeChange}
              inputStyle={{ opacity: 0.4 }}
            />
          </Grid>
          <Grid item xs={12} mt={4}>
            <StyledButton
              variant="contained"
              type="submit"
              loading={formikProps.isSubmitting}
            >
              <Typography
                sx={{
                  fontWeight: 800,
                }}
              >
                Continuar
              </Typography>
            </StyledButton>
          </Grid>
          <Grid item xs={12}>
            {isLoadingResendCode ? (
              <Box width={"100%"}>
                <LinearProgress />
              </Box>
            ) : (
              <Link
                color="titles.main"
                underline="none"
                onClick={handleResendCode}
                sx={{
                  cursor: "pointer",
                  borderBottom: `1px solid ${theme.palette.subTitles.main}`,
                }}
              >
                Reenviar código
              </Link>
            )}
          </Grid>
        </Grid>
      </StyledCard>
    </AnimatedCard>
  );
};

export default SecurityAuthCard;
