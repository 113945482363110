export interface QueryTypesNewPassword {
  email: string;
  verification_code: string;
}

export const routesConfig = {
  HOME: "*",
  LOGIN: "/login",
  LOGOUT: "/logout",
  FORGOT_PASSWORD: "/forgot",
  CONFIRM_EMAIL: "/confirm-email",
  UPDATE_PASSWORD_FIRST_ACCESS: "/update-password",
  SECURITY_AUTH: "/security-auth",
  SIGNUP: "/signup",
  NEW_PASSWORD: (query?: QueryTypesNewPassword) => {
    return !query
      ? "/change-password"
      : `change-password?email=${query.email}&verification_code=${query.verification_code}`;
  },
};
