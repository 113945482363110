import { Box, BoxProps, SxProps, Theme } from "@mui/system";
import { generateFilter } from "colorize-filter";

export type IconType = "close-brightness" | "success" | "left-arrow" | "up-arrow";

export interface StyledIconsProps extends BoxProps<"img"> {
  iconType: IconType;
  /**
   * Hex color
   */
  iconColor?: string;
}

const StyledIcons: React.FC<StyledIconsProps> = ({
  iconType,
  iconColor,
  ...props
}) => {
  const sxProps: SxProps<Theme> = {
    width: "1.8rem",
    height: "1.8rem",
  };

  const selectIcon = () => {
    switch (iconType) {
      case "success": {
        return { src: "/images/icons/success.svg", alt: "success" };
      }
      case "close-brightness": {
        return { src: "/images/icons/close.svg", alt: "close" };
      }
      case "left-arrow": {
        return {
          src: "/images/icons/left-arrow.svg",
          alt: "seta esquerda",
        };
      }
      case "up-arrow": {
        return {
          src: "/images/icons/left-arrow.svg",
          alt: "seta pra cima",
          style: { transform: "rotate(90deg)" },
        };
      }
    }
  };

  const icon = selectIcon();

  return icon ? (
    <Box
      component="img"
      {...props}
      {...icon}
      sx={{
        ...sxProps,
        ...props.sx,
        filter: iconColor ? generateFilter(iconColor) : undefined,
      }}
    />
  ) : (
    <></>
  );
};

export { StyledIcons };
