import { Grid, GridProps } from "@mui/material";
import { styled } from "@mui/system";

export interface StyledLayoutProps extends GridProps<React.ElementType> {

}

const StyledGridContainer = styled(
  ({ children, sx, ...props }: StyledLayoutProps) =>
    <Grid container
      sx={{
        width: "100%",
        height: "100vh",
        backgroundImage: `url(/image-login-background-laptop.png)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      {...props}
    >

      <Grid item xs={7} {...props} display={{ xs: 'none', md: 'block' }}> </Grid>

      <Grid item xs={12} md={5}
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }} {...props}>
        {children}
      </Grid>

    </Grid >
)(({ theme }) => ({}));

export { StyledGridContainer };