import React, { createContext, useContext  } from "react";
import { appIocContainer } from "../../ioc";
import { IIocContext } from "./IIocContext";

const IocContext = createContext<IIocContext>({} as IIocContext);

interface Props {
    children: React.ReactNode;
}

const IocProvider: React.FC<Props> = ({children}) => {
    return (
        <IocContext.Provider value={{serviceContainer: appIocContainer}}>
            {children}
        </IocContext.Provider>
    );
};

const useIocContext = (): IIocContext => {
    const context = useContext(IocContext);
    if(!Object.keys(context).length){
        throw new Error("Ioc Context must be used inside an IocProvider component");
    }
    return context;
}

export { useIocContext, IocProvider };