import { useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import useMedia from 'use-media';

interface AnimatedCardProps {
    children: React.ReactNode
}

const AnimatedCard: React.FC<AnimatedCardProps> = ({ children }) => {
    const isMobile = useMedia({ maxWidth: 900 });

    const [props, set] = useSpring(() => ({
        opacity: 0,
        transform: 'translate3d(0, -60%, 0)'
    }));

    useEffect(() => {
        set({
            opacity: 1,
            transform: 'translate3d(0, 0%, 0)'
        })
    }, [set]);


    return (
        <animated.div style={{
            ...props,
            width: isMobile ? '95%' : '95%',
            height: '83%',
            display: 'flex',
            justifyContent: 'center',
        }}>
            {children}
        </animated.div>);
}

export default AnimatedCard;