import useMedia from "use-media";
import DesktopPageView from "./DesktopPageView";
import MobilePageView from "./MobilePageView";

function LoginDefault() {
  const isMobile = useMedia({ maxWidth: 1278 });

  if (isMobile) {
    return <MobilePageView />;
  }

  return <DesktopPageView />;
}

export default LoginDefault;
